import React, { useEffect, useState } from "react";
import Navbar from "components/Navbar/navbar";
import Numbers from "components/Numbers/numbers";
import CallToAction from "components/Call-to-action/call-to-action";
import Footer from "components/Footer/footer";
import Intro4 from "components/Intro4/intro4";
import AboutUs3 from "components/About-us3/about-us3";
import DarkTheme from "layouts/Dark";
import ServicesShowcasesGrid from "components/Elf/ShowcaseElf/Showcase-grid";
import Verticals from "components/Services/Verticals";
import ShowcaseMasanory from "components/ShowcaseMasonry/ShowcaseMasanory";

// import Services3Elf from "components/Elf/Services3Elf/Services3Elf";
const Homepage5 = () => {
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);

  React.useEffect(() => {
    var navbar = navbarRef.current;

    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });
  }, [navbarRef]);

  return (
    <DarkTheme>
      <Navbar nr={navbarRef} lr={logoRef} />
      <Intro4 />
      <AboutUs3 />
      {/* <Services3 /> */}
      {/* { !!localStorage.getItem('oldServices') ?
        <Services3 /> : <ServicesShowcasesGrid />
      } */}
      {/* <Services3Elf  contentdata={AboutUs}/> */}
      {/* <ServicesShowcasesGrid /> */}
      <Verticals />
      <Numbers />
      <ShowcaseMasanory/>
      <CallToAction />
      <Footer />
    </DarkTheme>
  );
};

export const Head = () => {
  return (
    <>
      <title>Elfonze Technologies</title>
      <meta
        key="description"
        name="description"
        content="Elfonze Technologies is a premier IT Services and Consulting, Product Development Engineering, ESDM , Supply Chain Services & Staff Augmentation & Training firm"
      />
    </>
  );
};

export default Homepage5;
